<template>
  <div>
    <b-card
      title=""
    >
      <b-row>
        <b-col
          cols="21"
          xl="6"
          class="d-flex justify-content-between flex-column"
        >
          <!-- User Avatar & Action Buttons -->
          <div class="d-flex justify-content-start">
            <b-avatar
              src="@/assets/images/avatars/9-small.png"
              variant="light-primary"
              size="104px"
              rounded
            />
            <div class="d-flex flex-column ml-1">
              <div class="mb-1">
                <h4 class="mb-0">
                  {{ userData.fullName }}
                </h4>
                <div class="d-flex">
                  <b-form-rating
                    v-model="rating"
                    variant="primary"
                    no-border
                    readonly
                  />
                  <span class="score_"><small>(4.5)</small></span>
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row class="featureSpace">
        <div class="outline_ feature_">
          <small class="m-1">日本語</small>
        </div>
        <div class="outline_ feature_">
          <small class="m-1">講師経験あり</small>
        </div>
        <div class="outline_ feature_">
          <small class="m-1">留学経験あり</small>
        </div>
        <div class="outline_ feature_">
          <small class="m-1">英語対応可能</small>
        </div>
        <div class="outline_ feature_">
          <small class="m-1">優しい</small>
        </div>
        <div class="outline_ feature_">
          <small class="m-1">ビジネス英語</small>
        </div>
        <div class="outline_ feature_">
          <small class="m-1">翻訳</small>
        </div>
        <div class="outline_ feature_">
          <small class="m-1">通訳</small>
        </div>
      </b-row>
      <b-row>
        <div class="m-1">
          初めまして！日本一ブラックな塾で講師をしていました。教え方には自信があります。皆さんとお会いできるのが楽しみです。質問はお気軽に！
        </div>
      </b-row>
      <b-row>
        <b-col>
          <div class="flex-wrap btnSpace_">
            <b-button
              variant="flat-primary"
              tag="a"
              class="btn-wishlist"
            >
              <feather-icon
                icon="MailIcon"
                class="mr-50"
              />
              <span>メッセージ</span>
            </b-button>
            <b-button
              variant="flat-primary"
              tag="a"
              class="btn-icon rounded-circle"
            >
              <feather-icon
                icon="MoreVerticalIcon"
                class=""
              />
            </b-button>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>

          <!-- User Stats -->
          <div class="d-flex align-items-center mt-2">
            <div class="d-flex align-items-center mr-2">
              <b-avatar
                variant="light-primary"
                rounded
              >
                <feather-icon
                  icon="DollarSignIcon"
                  size="18"
                />
              </b-avatar>
              <div class="ml-1">
                <h5 class="mb-0">
                  24 人
                </h5>
                <small>累計受講者数</small>
              </div>
            </div>

            <div class="d-flex align-items-center">
              <b-avatar
                variant="light-success"
                rounded
              >
                <feather-icon
                  icon="TrendingUpIcon"
                  size="18"
                />
              </b-avatar>
              <div class="ml-1">
                <h5 class="mb-0">
                  120 件
                </h5>
                <small>月間表示</small>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  // BRow, BCol, BButton, BCardText,
  BCard, BAvatar, BCol, BButton, BRow, BFormRating,
} from 'bootstrap-vue'
import menulist from '@/components/conf/teacher/JPCAREER様_学習PF_講師_レッスン登録.json'
import makeDate from '@/components/basic/lesson/js/LessonDate'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BCard,
    BAvatar,
    BFormRating,
    // BCardText,
    // BImg,
  },
  directives: {
    Ripple,
  },
  props: {
    dataDic: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      menulist,
      showList: [],
      userData: {
        // avatar: require('@/assets/images/avatars/9-small.png'),
        fullName: 'Sanomaru',
        pr: '初めまして。一緒に勉強していきましょう。',

      },
      rating: 4.5,
    }
  },
  watch: {
    dataDic: {
      handler() {
        if (this.dataDic !== {}) this.$store.dispatch('lesson/updateApplyLessonDic', this.dataDic)
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    this.showList = this.menulist.filter(v => v.showStudent === true)
    // this.makeLessonDate()
  },
  methods: {
    async makeLessonDate() {
      const response = await makeDate({ data: this.dataDic.lessonDate })
      if (response.status === 'success') {
        window.console.log('success', response.status, response.data)
        this.$store.dispatch('lesson/updateLessonDate', response.data)
      } else {
        window.console.log('error', response.msg)
      }
    },
    apply() {
      this.$store.dispatch('lesson/updateApplyLessonDic', this.dataDic)
      this.$router.push({ path: '/lesson-apply-confirm', query: { id: this.$route.query.id } })
    },
    cancel() {
      this.$router.push({ name: 'lesson-list' })
    },
  },
}
</script>

<style scoped>
.title_ {
  font-size: 0.4rem;
}
.outline_ {
  outline: 1px solid rgb(134, 134, 134);
  /* padding: 1%; */
  border-radius: 5px;
  /* font-size: 0.8rem; */
}
.score_ {
  margin: 6% 0 0 -5%;
}
.featureSpace {
  margin: 2% 0 3% 1%;
}
.feature_ {
  margin: 0 2% 2% 0;
}
.btnSpace_ {
  display: flex;
  justify-content: space-between;
}
</style>
