export default function makeDate(payload) {
  const { data } = payload
  try {
    /* eslint-disable */
    for (let i in data) {
      let item = data[i]
      item.title = 'レッスン可能日'
      item.allDay = false
      item.id = i
      item.extendedProps = {
        calendar: "レッスン完了",
      }
    }
    /* eslint-enable */
    const res = {
      status: 'success',
      data,
    }
    return res
  } catch (error) {
    const res = {
      status: 'error',
      msg: error,
    }
    return res
  }
}
