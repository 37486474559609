import firebase from 'firebase/app'
import store from '@/store'

// 受講生が講座を申し込むとき
export default async function addLesson(payload) {
  const { collection, data } = payload
  try {
    const uid = store.getters['userBasic/uid']
    const mail = store.getters['userBasic/mail']
    const promise = await firebase.firestore().collection(collection).doc()
    data.docID = promise.id
    data.studentUid = [uid]
    data.studentEmail = [mail]
    data.teacherUid = data.uid
    delete data.uid
    data.insertTime = new Date()
    data.isComment = false
    data.displayStatus = 'active'

    await firebase.firestore().collection(collection).doc(promise.id).set(data, { merge: true })

    const response = {
      status: 'success',
    }
    return response
  } catch (error) {
    const response = {
      status: 'error',
      msg: error,
    }
    return response
  }
}
