<template>
  <div>
    <h2>レッスンお申込みの確認</h2>
    <div class="">
      テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
    </div>
    <b-alert
      variant="primary"
      show
    >
      <div class="alert-body mt-1 mb-2">
        内容をお確かめください。キャンセルポリシーに従ってキャンセル料が発生する場合がございます。
      </div>
    </b-alert>
    <b-card
      v-if="Object.keys(inputForm).length > 0"
      title=""
      img-height="200px"
      :img-src="inputForm.lessonPic"
    >
      <b-card-text>
        <h3>お申込内容</h3>
        <hr>
      </b-card-text>
      <b-list-group flush>
        <b-list-group-item>
          <b-row>
            <b-col lg="3">
              レッスン名
            </b-col>
            <b-col>
              {{ inputForm.lessonName }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col lg="3">
              講師
            </b-col>
            <b-col>
              <!-- {{ inputForm.teacherNickName }} -->
              <!-- {{ inputForm.lessonName }} -->
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col lg="3">
              内容
            </b-col>
            <b-col>
              {{ inputForm.lessonexplain }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col lg="3">
              開始日時
            </b-col>
            <b-col>
              {{ new Date(bookDate.startTime) | formatDate }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col lg="3">
              終了日時
            </b-col>
            <b-col>
              {{ new Date(bookDate.endTime) | formatDate }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col lg="3">
              時間
            </b-col>
            <b-col>
              {{ inputForm.lessonTime.labelName }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col lg="3">
              コイン
            </b-col>
            <b-col>
              {{ inputForm.coin }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col lg="3">
              キャンセルポリシー
            </b-col>
            <b-col>
              {{ inputForm.cancelPolicy }}
            </b-col>
          </b-row>
        </b-list-group-item>
      </b-list-group>
      <b-alert
        variant="primary"
        show
      >
        <div class="alert-body mt-1 mb-2">
          テキストテキストテキストテキストテキストテキストテキストテキスト
        </div>
      </b-alert>
      <b-row class="mt-2">
        <b-col offset-md="2">
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="outline-secondary"
            class="mr-1"
            @click="cancel"
          >
            戻る
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="apply"
          >
            この内容で申し込む
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BRow, BCol, BButton, BCard, BCardText, BAlert, BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import menulist from '@/components/conf/teacher/JPCAREER様_学習PF_講師_レッスン登録.json'
import addLesson from '@/firestore/lesson/create'
import getSingleData from '@/firestore/data/getByDocID'
import formatDate from '@/components/js/filter/dateFormat'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BCard,
    BCardText,
    // BSpinner,
    BAlert,
    BListGroup,
    BListGroupItem,
  },
  directives: {
    Ripple,
  },
  filters: {
    formatDate(val) {
      return formatDate(val, 'yyyy年MM月dd日HH時mm分')
    },
  },
  props: {
  },
  data() {
    return {
      menulist,
      status: 1,
      inputForm: {},
    }
  },
  computed: {
    ...mapGetters('lesson', ['applyLessonDic', 'bookDate']),
  },
  async mounted() {
    const data = {
      collection: 'lessonRegistration',
      docID: this.$route.query.id,
    }
    const response = await getSingleData(data)
    if (response.status === 'success') {
      window.console.log('success', response.status)
      this.inputForm = response.data
    } else {
      window.console.log('error', response.msg)
    }
  },
  methods: {
    async apply() {
      this.status = 2
      const data = {
        collection: 'lesson',
        data: { ...this.inputForm, ...this.bookDate },
        // data: { ...this.applyLessonDic, ...this.bookDate },
      }
      // window.console.log('🌹', data.data)
      const response = await addLesson(data)
      if (response.status === 'success') {
        this.success()
      } else {
        window.console.log('error', response.msg)
      }
    },
    showBtn() {
      this.$ref.sbchild.$emit('success')
    },
    success() {
      this.$swal({
        title: '登録完了！',
        text: 'データが正常に登録されました。',
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      }).then(() => {
        this.$router.push({ name: 'student-lesson-done-list' })
        // this.$router.push({ name: 'lesson-apply-thanks' })
        this.status = 1
        this.$store.dispatch('lesson/updateApplyLessonDic', {})
      })
    },
    cancel() {
      this.$router.push({ path: '/lesson', query: { id: this.$route.query.id } })
    },
  },
}
</script>
