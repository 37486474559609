<template>
  <b-row class="match-height">
    <b-col lg="8">
      <ConfirmLessonApply />
    </b-col>
    <b-col lg="4">
      <TeacherInfo />
      <Sidebar />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import ConfirmLessonApply from '@/components/basic/lesson/ConfirmLessonApply.vue'
import TeacherInfo from '@/components/basic/lesson/TeacherInfo.vue'
import Sidebar from '@/components/student/sidebar/test/Sidebar.vue'

export default {
  components: {
    BRow,
    BCol,
    ConfirmLessonApply,
    TeacherInfo,
    Sidebar,
  },
}
</script>
